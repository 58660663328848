<template>
  <button class="base-button__back back-btn_wrap dflex_nowrap" @click.prevent="goToPreviousPage">
    <div class="dflex">
      <CIcon name="cilArrowCircleLeft" alt="Назад"></CIcon>
    </div>
    <div class="text">
      <slot name="text">
        Назад
      </slot>
    </div>
  </button>
</template>

<script>
export default {
  methods: {
    goToPreviousPage() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss">
.back-btn_wrap {
  padding-top: 1rem;
}

.base-button__back {
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;
  color: $white;
  background: $mainGrey;
  border: 1px solid $mainGrey;
  border-radius: 0.25rem;
  align-items: center;
}

// effects
.base-button__back:hover {
  color: $white;
  background: $darkGrey;
  border: 1px solid $asfalt;
}

.text {
  padding-left: 0.25rem;
}
</style>
