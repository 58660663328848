// import URL from '../basUrl';
import {
  getOptionsWithCookie
} from './common';

// Редактирование правил callback
const RULE = 'https://api.onlinelogic.ru/CallbackService/CallbackSettingsRead';
const CALL_NUMBER = 'https://api.onlinelogic.ru/CallbackService/GetClientAnis';

// Удаление правила
export const DELETE_URL = 'https://api.onlinelogic.ru/CallbackService/CallbackSettingsDelete';

export const CALL_BACK = 'https://api.onlinelogic.ru/CallbackService/GetCallbackServices';

// // Редактирование и создание правила по нажатии на кнопку "Сохранить"
export const UPDATE_URL = 'https://api.onlinelogic.ru/CallbackService/CallbackSettingsUpdate';
export const CREATE_URL = 'https://api.onlinelogic.ru/CallbackService/CallbackSettingsCreate';

export const getRuleOptions = async (id) => {
  const response = await fetch(`${RULE}?id=${id}`, getOptionsWithCookie());
  return response.json();
};

export const getUserNumbers = async () => {
  const response = await fetch(CALL_NUMBER, getOptionsWithCookie());
  return response.json();
};
