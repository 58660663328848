<template>
  <CreateRule class="info-block_wrapper_size" />
</template>

<script>
import CreateRule from '@/components/services/call_back/CreateRule.vue';

export default {
  components: {
    CreateRule
  }
};
</script>

<style></style>
